// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "bootstrap-sass";
import "turbolinks";
import "./prism";

(function ($) {
  $.fn.carouselSwipe = function () {
    var touch_x;

    $(this).on('touchstart', function (event) {
      touch_x = event.originalEvent.touches[0].clientX;
    });

    $(this).on('touchend', function (event) {
      var pixels_for_slide = 30;
      var movement = event.originalEvent.changedTouches[0].clientX - touch_x;

      if (movement >= pixels_for_slide) {
        $(this).carousel('prev');
      } else if (movement <= -(pixels_for_slide)) {
        $(this).carousel('next');
      }
    });
  };

  $.fn.prettyPre = function (method) {
    var defaults = {
      ignoreExpression: /\s/ // what should be ignored?
    };

    var methods = {
      init: function (options) {
        this.each(function () {
          var context = $.extend({}, defaults, options);
          var $obj = $(this);
          var usingInnerText = true;
          var text = $obj.get(0).innerText;

          // some browsers support innerText...some don't...some ONLY work with innerText.
          if (typeof text == "undefined") {
            text = $obj.html();
            usingInnerText = false;
          }

          // use the first line as a baseline for how many unwanted leading whitespace characters are present
          var superfluousSpaceCount = 0;
          var currentChar = text.substring(0, 1);

          while (context.ignoreExpression.test(currentChar)) {
            currentChar = text.substring(++superfluousSpaceCount, superfluousSpaceCount + 1);
          }

          // split
          var parts = text.split("\n");
          var reformattedText = "";

          // reconstruct
          var length = parts.length;
          for (var i = 0; i < length; i++) {
            // cleanup, and don't append a trailing newline if we are on the last line
            reformattedText += parts[i].substring(superfluousSpaceCount) + (i == length - 1 ? "" : "\n");
          }

          // modify original
          if (usingInnerText) {
            $obj.get(0).innerText = reformattedText;
          } else {
            // This does not appear to execute code in any browser but the onus is on the developer to not
            // put raw input from a user anywhere on a page, even if it doesn't execute!
            $obj.html(reformattedText);
          }
        });
      }
    }

    if (methods[method]) {
      return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
    }
    else if (typeof method === "object" || !method) {
      return methods.init.apply(this, arguments);
    }
    else {
      $.error("Method " + method + " does not exist on jQuery.prettyPre.");
    }
  }
})(jQuery);

$(document).on("click", "header h1", function (_event) {
  window.location = "/";
});

$(document).on("click", "a[href='#contact']", function (event) {
  event.stopPropagation();
  event.preventDefault();

  $("html, body").animate({
    scrollTop: $("#contact_form").offset().top
  }, 2000);

  $("#contact_name").focus();
});

Array.prototype.to_sentence = function () {
  return this.join(", ").replace(/,\s([^,]+)$/, ' en $1')
}

window.showFailDialog = function () {
  $(' \
    <div class="modal fade" tabindex="-1" role="dialog">\
      <div class="modal-dialog" role="document">\
        <div class="modal-content">\
          <div class="modal-header">\
            <h4 class="modal-title">Oeps!</h4>\
          </div>\
          <div class="modal-body">\
            Sorry, er ging iets mis bij het verwerken van de aanvraag. Probeer u het a.u.b. later nog eens of\
            neem contact met ons op via telefoon op 040-7110344 (werkdagen van 08:00 tot 17:00).\
          </div>\
          <div class="modal-footer">\
            <button type="button" class="btn btn-default" data-dismiss="modal">Ok</button>\
          </div>\
        </div>\
      </div>\
    </div>\
  ').appendTo(document.body).modal('show')
}

$(document).on("submit", "form", function (event) {
  event.stopPropagation();
  event.preventDefault();

  let $form = $(this);

  $.ajax({
    method: $form.attr("method"),
    url: $form.attr("action"),
    data: $form.serialize(),
    dataType: "json",

    beforeSend: function (_jqXHR, _settings) {
      $("input[type=submit]", $form).prop("disabled", true).val("Een moment geduld a.u.b.")
    },

    complete: function (_jqXHR, _textStatus) {
      $("input[type=submit]", $form).prop("disabled", false).val("Verstuur")
    },

    error: function (_jqXHR, _textStatus, _errorThrown) {
      window.showFailDialog()
    },

    success: function (data, _textStatus, _jqXHR) {
      if (data.status == "failed") {
        window.showFailDialog();
      } else if (data.status == "invalid") {
        grecaptcha.reset();
        $.each($("input, textarea", $form), function () {
          var input = $(this);
          var name = input.prop("name").replace(/.*\[(.*?)\].*/, "$1");

          var form_group = input.parents(".form-group:first")
          $('.help-block', form_group).remove();

          if (name in data.errors) {
            form_group.addClass("has-error");
            $("<span class='help-block'>" + data.errors[name].to_sentence() + "</span>").appendTo(form_group);
          } else if (form_group.length > 0) {
            form_group.removeClass("has-error");
          }
        });

        if (data.captcha_feedback && data.captcha_feedback == "error") {
          $(".contact-form-captcha", $form).addClass("has-error");
          $("<span class='help-block'>is niet juist ingevuld</span>").appendTo($(".contact-form-captcha", $form)
          );
        } else {
          $(".contact-form-captcha", $form).removeClass("has-error");
        }
      } else {
        window.form_submitted = $($('.form_submitted', $form).html()).appendTo(document.body)
        $form.replaceWith(form_submitted);
        $("html, body").animate({
          scrollTop: window.form_submitted.offset().top
        }, 1000);
      }
    }
  });
});

$(function () {
  $(".faq pre").prettyPre();
  $(".carousel").carouselSwipe();
});

Turbolinks.start();

$(document).on('page:load ready', function (_event) {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $("#move_up").fadeIn();
    } else {
      $("#move_up").fadeOut();
    }
  });

  $("#move_up").click(function () {
    $('html, body').animate({ scrollTop: 0 }, 800);
    return false;
  });

  Prism.highlightAll();
});

$(document).on("focus", "*[data-show-captcha-on-focus]", function (_event) {
  $(this).parents('form').find('.contact-form-captcha.hidden').removeClass('hidden')
});

$(document).on("blur", "*[data-show-captcha-on-focus]", function (_event) {
  if (this.value == '') {
    $(this)
      .parents("form")
      .find(".contact-form-captcha:not(.hidden)")
      .addClass("hidden");
  }
});
